import React from "react";

const ChildSafetyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 text-gray-800 bg-white rounded-xl shadow-md">
      <h1 className="text-3xl font-bold mb-4">Child Safety Policy</h1>
      <p className="mb-2"><strong>Last updated:</strong> April 6, 2025</p>

      <p className="mb-4">
        At Attic, we are committed to protecting children from exploitation, abuse, and harm.
        We strictly prohibit any form of child sexual abuse material (CSAM) on our platform.
        Our app complies with all applicable child protection laws and regulations.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">1. Zero-Tolerance for CSAE</h2>
      <p className="mb-4">
        We do not tolerate any content or behavior that promotes or facilitates child sexual
        abuse or exploitation (CSAE). Accounts found engaging in such activity will be permanently
        banned and reported to the authorities.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">2. Reporting CSAE</h2>
      <p className="mb-4">
        Users can report inappropriate or suspicious content through our in-app reporting tools.
        Reports are reviewed by our safety team immediately.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">3. Moderation & Detection</h2>
      <ul className="list-disc ml-6 mb-4">
        <li>Automated systems monitor for known CSAM hashes and suspicious activity.</li>
        <li>Human moderators review flagged content and user reports.</li>
        <li>AI tools detect grooming behavior and inappropriate messaging patterns.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">4. Cooperation with Authorities</h2>
      <p className="mb-4">
        We report and work with NCMEC and law enforcement to combat online exploitation.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">5. Age Restrictions & Parental Controls</h2>
      <p className="mb-4">
        Our platform is for users aged 18 and above. We use age gates and recommend parental monitoring.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">6. Contact</h2>
      <p>
        For questions or concerns, contact us at:{" "}
        <a href="mailto:harimaurya762@gmail.com" className="text-blue-600 underline">
          harimaurya762@gmail.com
        </a>
      </p>
    </div>
  );
};

export default ChildSafetyPolicy;
